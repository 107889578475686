* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Avenir Next Cyr";
}

body {
  transform: scale(calc(344px + 120 * (100vw - 768px) / 256));
}

a {
  text-decoration: none;
}

h1 {
  font-size: 20px;
  font-weight: 750;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  padding: 8px 0px;
}

.fon {
  background-repeat: no-repeat;
  background-image: url(/public/img/fon.jpg);
  background-size: cover;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -2;
  width: 100%;
}

.vibor {
  position: relative;
}
.vibor > .container {
  height: 90vh;
}
.vibor_header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px;
}
.vibor_info {
  width: 74%;
  background: #FFFFFF;
  border-radius: 25px;
  padding: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0px auto;
}
.vibor_name {
  max-width: 224px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vibor_news {
  width: 100%;
}
.vibor_news_title {
  font-size: 50px;
  text-align: center;
  color: #FFFFFF;
}
.vibor_news-header {
  display: flex;
  justify-content: center;
  gap: 50px;
  cursor: pointer;
}

.vibor_news {
  transition: 300ms;
  animation: opp 1s linear;
  transform: translatey(0px);
}

.news_more {
  padding: 5px 15px;
  font-size: 26px;
  border-color: #07daff;
  border-radius: 25px;
  cursor: pointer;
}
.news_more_body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin: 20px 0px;
}
.news_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 35px auto;
  padding: 0px 25px;
  width: 74%;
  transition: 300ms;
  animation: opp 2s linear;
  transform: translatey(0px);
  overflow: hidden;
}
@keyframes opp {
  0% {
    opacity: 0;
    transform: translatey(180px);
  }
  100% {
    opacity: 1;
    transform: translatey(0px);
  }
}
.news__item {
  width: 100%;
  background: #FFFFFF;
  border-radius: 25px;
  overflow: hidden;
  color: black;
  cursor: pointer;
}
.news__item_img {
  width: 100%;
  height: 250px;
  overflow: hidden;
}
.news__item_img img {
  width: 100%;
  height: 100%;
}
.news__content {
  padding: 15px;
}

.arrow {
  position: relative;
  transform: rotate(0deg);
  cursor: pointer;
  display: block;
}
.arrow.left {
  transform: rotate(270deg);
}
.arrow.right {
  transform: rotate(90deg);
}

.popupNEwsi {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.431372549);
  z-index: 99999;
}
.popupNEwsi_img {
  max-width: 50%;
  max-height: 50%;
}
.popupNEwsi_body {
  margin: 50px;
  width: auto;
  height: 90%;
  background: #FFFFFF;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
  position: relative;
}
.popupNEwsi_exit {
  color: red;
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}

.outer {
  position: relative;
  margin: auto;
  width: 70px;
  cursor: pointer;
}
.outer .inner {
  width: inherit;
  text-align: center;
}
.outer label {
  font-size: 0.8em;
  line-height: 4em;
  text-transform: uppercase;
  color: #030303;
  transition: all 0.3s ease-in;
  opacity: 0;
  cursor: pointer;
}
.outer .inner:before, .outer .inner:after {
  position: absolute;
  content: "";
  height: 1px;
  width: inherit;
  background: #07daff;
  left: 0;
  transition: all 0.3s ease-in;
}
.outer .inner:before {
  top: 50%;
  transform: rotate(45deg);
}
.outer .inner:after {
  bottom: 50%;
  transform: rotate(-45deg);
}
.outer:hover label {
  opacity: 1;
}
.outer:hover .inner:before, .outer:hover .inner:after {
  transform: rotate(0);
}
.outer:hover .inner:before {
  top: 0;
}
.outer:hover .inner:after {
  bottom: 0;
}

.container {
  width: auto;
  margin: 0 auto;
  position: relative;
}

.text {
  font-family: "Avenir Next Cyr";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  width: 740px;
}

.logo {
  width: 198px;
  height: 144px;
}

.logo img {
  width: 100%;
  height: 100%;
}

.vibor_name p {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.vibor_name_img {
  border-radius: 50%;
  overflow: hidden;
  width: 137px;
  height: 137px;
}

.vibor_name_img img {
  width: 100%;
  height: 100%;
}

.vibor_info_slova_box {
  padding: 35px 15px 15px 50px;
  position: relative;
  z-index: 2;
}

.arrow span {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
.vibor_info_slova {
  width: 307px;
  background-repeat: no-repeat;
  background-image: url(/public/img/fon2.png);
  background-size: 307px 196px;
  height: 196px;
}

.vibor_info_slova_box p {
  font-size: 16px;
  font-style: italic;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  /* width: 242px; */
}

.vibor_info_slova_box p::after {
  content: url(/public/img/1.png);
  top: 0;
  left: -31px;
  position: absolute;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.vibor_info_contact {
  display: flex;
  justify-content: space-between;
  gap: 33px;
}

.vibor_info_contact_qip {
  width: 102px;
  height: 102px;
}

.vibor_info_contact_link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 23px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  border-radius: 25px;
  transition: 300ms;
}

.tg {
  background: #29A9EB;
}

.vk {
  background: #457EC1;
}

.tg:hover {
  background: #2188bb;
}

.vk:hover {
  background: #325c8b;
}

.vibor_mob {
  display: none;
}

@media only screen and (max-width: 1250px) {
  .vibor {
    height: auto;
    padding: 25px 0px;
  }
  .news_list {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    margin: 20px auto;
  }
  .vibor_info {
    flex-wrap: wrap;
    /* display: -webkit-box; */
    gap: 25px;
  }
  .vibor_info_slova {
    margin-left: auto;
  }
  .vibor_name {
    transform: scale(1.5);
    position: relative;
    top: 88px;
    left: 49px;
  }
  .vibor_info_contact {
    margin-left: auto;
  }
  /* .vibor_name {
      margin: auto;
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      box-ordinal-group: 1;
  }
  .vibor_info_slova {
      margin: auto;
      -webkit-box-ordinal-group: 3;
      -moz-box-ordinal-group: 3;
      box-ordinal-group: 3;
  }
  .vibor_info_contact {
      margin:auto;
      -webkit-box-ordinal-group: 2;
      -moz-box-ordinal-group: 2;
      box-ordinal-group: 2;
  } */
}
@media only screen and (max-width: 963px) {
  .vibor {
    display: none;
  }
  .vibor_name {
    transform: scale(1);
    position: static;
  }
  .news_list {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
    margin: 20px auto;
  }
  .vibor_mob {
    display: block;
    height: auto;
    padding: 20px 0px;
  }
  .container {
    width: 90%;
    margin: 0 auto;
  }
  .text {
    margin-top: 50px;
    width: 100%;
  }
  .vibor_info {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 60px 10px;
    flex-wrap: nowrap;
  }
  .vibor_info_slova {
    position: relative;
    margin: 40px 0px;
    width: 112%;
    background-image: url(/public/img/fon2_mob.png);
    background-size: 100% 196px;
  }
  .fon {
    background-repeat: no-repeat;
    background-image: url(/public/img/fon.jpg);
    background-size: cover;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -2;
    width: 100%;
  }
  .vibor_info_slova_box {
    padding: 68px 15px 15px 49px;
  }
  .vibor_info_contact {
    flex-direction: column;
    width: 100%;
  }
  .vibor_info_contact_link {
    width: 100%;
    height: 48px;
    justify-content: flex-start;
  }
  .tg::before {
    content: url(/public/img/tg2.svg);
    padding-right: 10px;
    width: 42px;
    height: 42px;
  }
  .vk::before {
    content: url(/public/img/vk2.svg);
    padding-right: 10px;
    width: 42px;
    height: 42px;
  }
  .vibor_header {
    padding: 50px 0px;
  }
}/*# sourceMappingURL=style.css.map */